<template>
  <div>
    <v-card :to="'/book/'+ book.slug"
    class="mx-auto my-2"
    max-width="275"
    >
      <v-img
        height="160px"
        width="100%"
        :src="getImage(book.cover)"
        class="white--text"
      >
      </v-img>
      <v-card-text>
        <div class="row">
            <div class="col-12 text-truncate">
                <b class="black--text">{{book.title}}</b> <br>
                <b class="orange--text">Rp {{ book.price.toLocaleString('id-ID') }}</b>
            </div>
        </div>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-icon class="mr-1">mdi-eye</v-icon> {{ book.views }}
     </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'book-item',
  props: ['book'],
  data: () => ({
  }),
  
}
</script>